import BetsModal from 'Common/Modals/BetsModal';
import FancyBook from 'Common/Modals/FancyBook';
import MatchSetting from 'Common/Modals/MatchSetting';
import NewsModal from 'Common/Modals/NewsModal';
import ToggleSwitch from 'Common/ToggleSwitch';
import { FC, useEffect, useState } from 'react';
import { Card, Tab, Nav, Button, Table, OverlayTrigger, Tooltip, Modal, Form, Popover, InputGroup } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';
import Draggable from 'react-draggable';
import ModalVideo from 'react-modal-video';
import BookModal from 'Common/Modals/BookModal';
import { BetLimits } from '.';
import MatchedOdds from './MatchedOdds';
import FancyMarket from './FancyMarket';

import snackbarUtil from 'Utils/snackBarUtil';
import DetailsTopBar from './DetailsTopBar';



interface Runner {
    selectionId: number;
    name: string;
    [key: string]: any;
}
export interface Market {
    market_id: string;
    marketName: string;
    runners: Runner[];
    [key: string]: any;
}

interface RunnerMarket {
    marketId: string;
    runners: Runner[];
    [key: string]: any;
}

interface DataItem {
    data: DataItemSub[],
    fancy_category: any
};

interface fancyCat {
    [key: string]: string

}

interface DataItemSub {
    fancy_id: string;
    name: string;
    fancy_name: string;
    selection_id: string;
    is_active: number;
    category: number;
    chronology: number;
    is_lock: boolean;
    is_created: number;
}
export interface Data1Item {
    SelectionId: string;
    RunnerName: string;
    LayPrice1: number;
    LaySize1: number;
    BackPrice1: number;
    BackSize1: number;
    GameStatus: string;
    MarkStatus: string;
    Category: number;
    Srno: string;
    Min: number;
    Max: number;
    fancy_id: string;
};

interface Props {
    setLimits: React.Dispatch<React.SetStateAction<BetLimits | undefined>>
    setEnalbleFancy: React.Dispatch<React.SetStateAction<number>>,
    updateFancy: boolean,
    limits: BetLimits | undefined,
    getLimitMarket: (id: string, name: string) => Promise<void>
    getLimit: () => Promise<void>
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    state: any
}

const DetailsLeft: FC<Props> = ({ setShow, setLimits, getLimit, state, setEnalbleFancy, updateFancy, limits, getLimitMarket }) => {
    const [mainMatch, setMainMatch] = useState(true);
    const [fancy, setFancy] = useState(true);
    const [tableBtn, setTableBtn] = useState();
    const [lock, setLock] = useState(true);
    const [matchDetails, setMatchDetails] = useState<Market[] | undefined>(undefined);
    const [matchDetailsRunner, setMatchDetailsRunner] = useState<RunnerMarket[] | undefined>(undefined);
    const [teamPosition, setTeamPosition] = useState<any>();
    const [limitData, setLimitData] = useState<LimitDataPayload>()
    const [fancyData, setFancyData] = useState<DataItem | undefined>(undefined);
    const [fancyLiveData, setFancyLiveData] = useState<Data1Item[] | undefined>(undefined);
    const [combineFancyData, setCombineFancyData] = useState<any>();

    const [combinedDataOdds, setCombinedDataOdds] = useState<Market[]>();
    const [showBook, setBook] = useState(false);
    const [showTrigger, setTrigger] = useState(false);
    const handleToggle = () => setTrigger(!showTrigger);
    const [matchSetting, setMatchSetting] = useState(false);
    const [showAbond, setAbond] = useState(false);
    const [showActive, setActive] = useState(false);
    const [showNews, setNews] = useState(false);
    const [showBets, setBets] = useState(false);
    const [showFancyBook, setFancyBook] = useState(false);
    const [limitName, setLimitName] = useState<string>("");
    const [marketIds, setMarketIds] = useState<string>("")

    const [marketName, setMarketName] = useState<string>("");
    const [abdonMsg, setAbdanMsg] = useState<string>("")
    const [newsData, setNewsData] = useState({
        id: "",
        name: "",
    })
    const [newsValue, setNewsValues] = useState<string>("")

    const toggleBook = (id: string) => {
        setBook(!showBook);
        setMarketIds(id)

    }
    const [isOpen, setIsOpen] = useState(false);
    const toggleVideo = () => setIsOpen(!isOpen);

    const { id, sportid } = useParams();

    const toggleMatchSetting = (ids: string, name: string) => {

        setMatchSetting(!matchSetting)
        if(ids){
            getLimitMarket(ids, name);
        }
        setLimitName(name);
        setMarketIds(ids)
    }
    const toggleAbond = (id: string, name: string, marketName: string) => {
        setAbond(true);
        setNewsData({
            id,
            name
        })
        setMarketName(marketName)
    }

    const toggleFancyBook = () => {
        setFancyBook(!showFancyBook)
    }

    const combine = true

    const getMatchDetails = async () => {
        const { response } = await authServices.getMatchedDetails({
            match_id: id || "",
            combine
        })
        setMatchDetails(response?.data);
        if (combine) {
           
            // const newCombinedData = response?.data.map((market:any) => {
            //     const marketData = teamPosition?.[market.market_id];

        
            //     if (!marketData) return market;
        
            //     const updatedRunners = market.runners.map((runner:any) => {
                
            //         const correspondingData = marketData.find(
            //             (d: any) => d.selection_id === runner.selectionId
            //         );
        
                   

            //         if (!correspondingData) return runner;
        
            //         return {
            //             ...runner,
            //             win_loss: correspondingData.win_loss,
            //             win_loss_total_exposure: correspondingData.win_loss_total_exposure,
            //         };
            //     });
        
            //     return { ...market, runners: updatedRunners };
            // });
            setCombinedDataOdds(response?.data);
        }

    }
    const getTeamPosition = async () => {
        const { response } = await authServices.getTeamPosition({
            match_ids: id || "",
        })
        setTeamPosition(response?.data);

    }
    const getMatchRunnerDetails = async () => {
        const marketIds: string[] = matchDetails?.map((market) => "ODDS_" + market.market_id) || [];
        if (marketIds.length === 0) return null;
        const { response } = await authServices.getMatchedDetailsRunner({
            match_id: id || "",
            marketIds
        })

        setMatchDetailsRunner(response?.data);
    }

    const getFancyData = async () => {
        const { response } = await authServices.getFancyData({
            match_id: id || "",
            combine
        })
        setFancyData(response);


    }
    const getFancyLiveData = async () => {
        const { response } = await authServices.getFancyLiveData({
            match_id: id || ""
        })
        setFancyLiveData(response?.data)
    }




    const [previousCombinedDataOdds, setPreviousCombinedDataOdds] = useState<any>();
    const [previousData, setPreviousData] = useState(null); 

    useEffect(() => {
        if (!matchDetails) return;
    
        setPreviousCombinedDataOdds(combinedDataOdds);
    
        if (!matchDetailsRunner || matchDetailsRunner.length === 0) {
            setCombinedDataOdds(matchDetails);
            return;
        }
    
        if (combine) return;
    
        const combinedData = matchDetails.map((market) => {
            const correspondingMarket = matchDetailsRunner.find(
                (runnerMarket) => runnerMarket.marketId === market.market_id
            );
    
            if (!correspondingMarket) return market;
    
            const combinedRunners = market.runners.map((runner) => {
                const correspondingRunner = correspondingMarket.runners.find(
                    (r) => r.selectionId === runner.selectionId
                );
    
                return correspondingRunner ? { ...runner, ...correspondingRunner } : runner;
            });
    
            return {
                ...market,
                ...correspondingMarket,
                runners: combinedRunners,
            };
        });
    
        const newCombinedData = combinedData.map((market) => {
            const marketData = teamPosition?.[market.market_id];
    
            if (!marketData) return market;
    
            const updatedRunners = market.runners.map((runner) => {
                const correspondingData = marketData.find(
                    (d: any) => d.selection_id === runner.selectionId
                );
    
                if (!correspondingData) return runner;
    
                return {
                    ...runner,
                    win_loss: correspondingData.win_loss,
                    win_loss_total_exposure: correspondingData.win_loss_total_exposure,
                };
            });
    
            return { ...market, runners: updatedRunners };
        });
    
        setCombinedDataOdds(newCombinedData);
    }, [matchDetails, matchDetailsRunner, teamPosition, combine]);


    useEffect(() => {
        const mergedData = {
            data: fancyData?.data?.map(item => {
                const correspondingItem = fancyLiveData?.find(d => d.fancy_id === item.fancy_id);
                return {
                    ...item,
                    ...(correspondingItem || {})
                };
            }),
            fancy_category: fancyData?.fancy_category,
        };
        setPreviousData(combineFancyData);
        setCombineFancyData(mergedData)

    }, [fancyLiveData, fancyData])


    useEffect(() => {
        getTeamPosition();
        const interval = setInterval(() => {
            getMatchDetails();
            getFancyData();
        }, combine ? 1000 : 10000);

        return () => clearInterval(interval);
    }, [id, combine]);

    useEffect(() => {

        if (!combine) {
            const interval = setInterval(() => {
                getMatchRunnerDetails();
                getFancyLiveData();
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [matchDetails, combine, updateFancy]);


    useEffect(() => {
        getMatchDetails();
        getFancyData();
        if (!combine) {
            getMatchRunnerDetails();
            getFancyLiveData();
        }
    }, [combine])




    useEffect(() => {
        if (combinedDataOdds) {

            const enable_fancy_id = combinedDataOdds[0]?.enable_fancy;
            setEnalbleFancy(enable_fancy_id)
        }
    }, [combinedDataOdds])

    const updateEvent = async () => {
        const { response } = await authServices.updateEvent({
            event: marketName,
            update: {
                news: newsValue
            },
            filter: {
                [newsData.name]: newsData.id,
            }
        })

        if (response.status) {
            snackbarUtil.success(response.data);
            setNews(false);
            getMatchDetails();
            getFancyData();
        } else {
            snackbarUtil.error(response.data)
        }
    }
    const oddsAbd = async () => {
        const { response } = await authServices.oddsAbondoned({
            [newsData.name]: newsData.id,
        })

        if (response.status) {
            snackbarUtil.success(response.msg);
            setAbdanMsg("");
            setAbond(false);
            getMatchDetails();
            getFancyData();
        } else {
            snackbarUtil.error(response.msg)
        }
    }

    const toggleNews = (market_id: string, name: string, market: string) => {
        setNews(!showNews)
        setNewsData({
            id: market_id,
            name: name
        })
        setMarketName(market)

    }
    const toggleBets = () => {
        setBets(!showBets)
    }


    const handleUpdateNews = () => {
        updateEvent();
    }


    const handleAbd = () => {
        if (abdonMsg?.length === 0) return null;
        oddsAbd();
    }


    const updateMarketStatue = async () => {
        const { response } = await authServices.updateMarketStatus({
            is_active: tableBtn === 0 ? 1 : 0,
            market_id: marketIds
        })
        if (response?.status) {
            snackbarUtil.success(response.msg)
            setActive(false);
            getMatchDetails();
            getFancyData();
        }
    }

    const activeUpdateStatus = () => {
        updateMarketStatue()
    }

    const getEventLimits = async()=>{
        const {response} = await authServices.getEventLimits({
            match_id: id || "",
            sport_id:sportid || ""
        })

        setLimitData(response?.data)
    }

    

    useEffect(()=>{
        getEventLimits();
    }, [id, sportid])

    return (
        <>
            <DetailsTopBar getMatchDetails={getMatchDetails} conbineData={combinedDataOdds && combinedDataOdds[0]} setLimits={setLimits} getLimit={getLimit} limits={limits} state={state} setShow={setShow} id={id} />
            <Tab.Container defaultActiveKey={'all'}>
                <Card className='p-2 mb-2'>
                    <div className='d-flex align-items-md-center justify-content-between flex-column flex-md-row'>
                        <Nav as="ul" variant='tabs' className="nav-tabs-custom rounded border-bottom-0 mx-0 flex-nowrap overflow-auto w-100" style={{ height: '36px' }}>
                            <Nav.Item as="li">
                                <Nav.Link className='text-nowrap' eventKey="all">All</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link className='text-nowrap' eventKey="Match Odds">Match Odds</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link className='text-nowrap' eventKey="Fancy">Fancy</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className='ml-auto d-flex align-items-center mt-2 mt-md-0'>
                            <ToggleSwitch On='On' Off='Off' id='mainMatch' onChange={() => setMainMatch(!mainMatch)} checked={mainMatch ? true : false} />
                            <Button variant='btn-ghost-dark py-1 px-2 ms-2' onClick={toggleVideo}><i className='fs-xl align-middle ri-tv-line'></i></Button>
                            <Button variant='btn-ghost-dark py-1 px-2'><i className='fs-xl align-middle ri-bar-chart-fill'></i></Button>
                        </div>
                    </div>
                </Card>

                {isOpen ? (
                    <Draggable defaultClassName='videoPlayer'>
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h4 className='mb-0 ps-3'>Move Player</h4>
                                <Button variant="light btn-sm" onClick={toggleVideo}>
                                    <i className="ri-close-line fs-xl align-middle"></i>
                                </Button>
                            </div>
                            <ModalVideo
                                channel="youtube"
                                isOpen={isOpen}
                                videoId="L61p2uyiMSo"
                                onClose={toggleVideo}
                            />
                        </div>
                    </Draggable>
                ) : null}


                <Tab.Content className="text-muted mt-">
                    <Tab.Pane eventKey="all">

                        <MatchedOdds 
                        previousCombinedDataOdds={previousCombinedDataOdds} 
                        limits={limits} 
                        setMarketIds={setMarketIds} 
                        setActive={setActive} 
                        getMatchDetails={getMatchDetails}
                        getFancyData={getFancyData} 
                        toggleAbond={toggleAbond} 
                        toggleNews={toggleNews} 
                        toggleMatchSetting={toggleMatchSetting} 
                        setLock={setLock} 
                        lock={lock} 
                        setTableBtn={setTableBtn} 
                        tableBtn={tableBtn} 
                        setFancy={setFancy} 
                        fancy={fancy} 
                        combinedDataOdds={combinedDataOdds} 
                        toggleBook={toggleBook} 
                        toggleBets={toggleBets} 
                        limitData={limitData}
                        teamPosition={teamPosition}
                        />

                        <FancyMarket 
                        previousData={previousData } 
                        limits={limits} 
                        getFancyData={getFancyData} 
                        toggleAbond={toggleAbond} 
                        toggleNews={toggleNews} 
                        setLock={setLock} 
                        lock={lock} 
                        toggleFancyBook={toggleFancyBook} 
                        toggleBook={toggleBook} 
                        toggleBets={toggleBets} 
                        toggleMatchSetting={toggleMatchSetting} 
                        combineFancyData={combineFancyData}
                        limitData={limitData} 
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Match Odds"><MatchedOdds teamPosition={teamPosition}  limitData={limitData} previousCombinedDataOdds={previousCombinedDataOdds} limits={limits} setMarketIds={setMarketIds} setActive={setActive} getMatchDetails={getMatchDetails}
                        getFancyData={getFancyData} toggleAbond={toggleAbond} toggleNews={toggleNews} toggleMatchSetting={toggleMatchSetting} setLock={setLock} lock={lock} setTableBtn={setTableBtn} tableBtn={tableBtn} setFancy={setFancy} fancy={fancy} combinedDataOdds={combinedDataOdds} toggleBook={toggleBook} toggleBets={toggleBets} /></Tab.Pane>
                    <Tab.Pane eventKey="Fancy"><FancyMarket  limitData={limitData} previousData={previousData} limits={limits} getFancyData={getFancyData} toggleAbond={toggleAbond} toggleNews={toggleNews} setLock={setLock} lock={lock} toggleFancyBook={toggleFancyBook} toggleBook={toggleBook} toggleBets={toggleBets} toggleMatchSetting={toggleMatchSetting} combineFancyData={combineFancyData} /></Tab.Pane>
                </Tab.Content>

                <MatchSetting  marketId={limitName === "market_id" ? "market_id" : "fancy_id"} name="Match Odds Settings" show={matchSetting} clickHandler={toggleMatchSetting} id={marketIds} getLimit={getEventLimits} limits={limits} shoeOdss={limitName !== "market_id" ? false : true} fancyShow={limitName === "market_id" ? false : true} />
                <Modal show={showAbond} onHide={() => setAbond(false)} className="zoomIn" scrollable>

                    <Modal.Body className='text-center '>
                        <Button variant="light btn-sm" className='position-absolute' style={{ right: '1rem' }} onClick={() => setAbond(false)}>
                            <i className="ri-close-line fs-xl align-middle"></i>
                        </Button>
                        <i className="bi bi-exclamation-triangle text-warning display-5"></i>
                        <p className='fs-lg mt-4'>Are you sure want to Abond {marketName}?</p>
                        <div className="form-floating">
                            <Form.Control
                                isInvalid={abdonMsg?.length === 0}
                                required
                                onChange={(e) => setAbdanMsg(e.target.value)}
                                value={abdonMsg}
                                type="text"
                                id="Content"
                                placeholder="Enter Yes"
                            />
                            <Form.Label htmlFor="Content">Label</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid input.
                            </Form.Control.Feedback>
                            <Form.Label htmlFor="Content">Label</Form.Label>
                        </div>
                        <div className='mt-4'>
                            <Button variant='light' className='mx-2' onClick={() => setAbond(false)}>No</Button>
                            <Button className='mx-2' onClick={handleAbd}>Yes</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <NewsModal handleUpdateNews={handleUpdateNews} setNewsValues={setNewsValues} newsValues={newsValue} show={showNews} clickHandler={toggleNews} />

                <BetsModal show={showBets} clickHandler={toggleBets} />

                <FancyBook show={showFancyBook} clickHandler={toggleFancyBook} />

                <BookModal marketIds={marketIds} show={showBook} clickHandler={toggleBook} />



                <Modal show={showActive} className="zoomIn" scrollable>
                    <Modal.Body className='text-center '>
                        <i className="bi bi-exclamation-triangle text-warning display-5"></i>
                        <p className='fs-lg mt-4'>Are you sure want to ?</p>
                        <div className='mt-4'>
                            <Button variant='light' className='mx-2' onClick={() => setActive(false)}>No</Button>
                            <Button className='mx-2' onClick={activeUpdateStatus}>Yes</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Tab.Container>
        </>
    );
};

export default DetailsLeft;