import StatementTable from "Common/Tables/StatementTable";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Flatpickr from 'react-flatpickr';

const Statement = () => {
    return (
        <>
        <Card>
            <Card.Body>
                <Form action="#">
                    <div className="d-flex align-items-center mb-3">
                        <div className="form-check me-3">
                            <Form.Check type="radio" name="statement" id="Statement" defaultChecked />
                            <Form.Label className="form-check-label" htmlFor="Statement">Statement</Form.Label>
                        </div>
                        <div className="form-check me-3">
                            <Form.Check type="radio" name="statement" id="FreeChips" />
                            <Form.Label className="form-check-label" htmlFor="FreeChips">Free Chips</Form.Label>
                        </div>
                        <div className="form-check me-3">
                            <Form.Check type="radio" name="statement" id="ProfitLoss" />
                            <Form.Label className="form-check-label" htmlFor="ProfitLoss">Profit Loss</Form.Label>
                        </div>
                        <div className="form-check me-3">
                            <Form.Check type="radio" name="statement" id="Commission" />
                            <Form.Label className="form-check-label" htmlFor="Commission">Commission</Form.Label>
                        </div>
                        <div className="form-check me-3">
                            <Form.Check type="radio" name="statement" id="Settlement" />
                            <Form.Label className="form-check-label" htmlFor="Settlement">Settlement</Form.Label>
                        </div>
                    </div>
                    <Row className="gy-3">
                        <Col lg={3}>
                            <Flatpickr
                                className="form-control"
                                pl
                                options={{
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                                defaultDate: ["2024-10-05"]
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <Flatpickr
                                className="form-control"
                                pl
                                options={{
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                                defaultDate: ["2024-10-05"]
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <Form.Control type="text" placeholder="Search Username" />
                        </Col>
                        <Col lg={3}>
                            <Button className="me-2">Submit</Button>                            
                            <Button variant="outline-primary">Cancel</Button>                            
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
        <Card>
            <Card.Body>
                <StatementTable />
            </Card.Body>
        </Card>
        </>
    );
};

export default Statement;