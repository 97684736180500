import ChipSummaryTable from "Common/Tables/ChipSummaryTable";
import StatementTable from "Common/Tables/StatementTable";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Flatpickr from 'react-flatpickr';

const ChipSummary = () => {
    return (
        <>
        <Row>
            <Col lg={6}>
                <Card className="ribbon-box border shadow-none mb-lg-0">
                    <Card.Header>
                        <div className="ribbon ribbon-success ribbon-shape">Plus Account</div>
                        <h5 className="fs-md text-end">Super Admin(lcbook247-prime)</h5>
                    </Card.Header>
                    <Card.Body>
                        <ChipSummaryTable type="success" />
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={6}>
                <Card className="ribbon-box border shadow-none mb-lg-0">
                    <Card.Header>
                        <div className="ribbon ribbon-danger ribbon-shape">Minus Account</div>
                        <h5 className="fs-md text-end">Super Admin(lcbook247-prime)</h5>
                    </Card.Header>
                    <Card.Body>
                        <ChipSummaryTable type="danger" />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    );
};

export default ChipSummary;