import TableContainer from "Common/TableContainer";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const StatementTable = () => {
    const[showList, setList] = useState(false)
    const toggleList = () => {
        setList(!showList)
    }
    const defaultTable =
    [
      { no: "10",},
      { no: "09",},
      { no: "08",},
      { no: "07",},
      { no: "06",},
      { no: "05",},
      { no: "04",},
      { no: "03",},
      { no: "02",},
      { no: "01",}
    ];
  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },

      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: () => {
            return (
                <div className="cursor-pointer"> 
                    <p className="mb-0">05-10-2024</p> 
                    <p className="mb-0">03:03:03 PM</p>
                </div>
            );
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: () => {
          return (
            <span className="cursor-pointer text-info" onClick={toggleList}>lcbook247-prime</span>
          );
        },
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        cell: () => {
            return (
                <div className="cursor-pointer text-primary"> 
                    <p className="mb-0">Spribe - (SPB aviator - roundId[6701158854ec2f0001181b3f] -</p> 
                    <p className="mb-0">SPB-ASIA-134279105458) - Loss [ User : Loss ]</p>
                </div>
            );
        },
      },
      {
        header: "Credit",
        accessorKey: "credit",
        enableColumnFilter: false,
        cell: () => {
            return (
              <span className="cursor-pointer text-success">155</span>
            );
        },
      },
      {
        header: "Debit",
        accessorKey: "debit",
        enableColumnFilter: false,
        cell: () => {
            return (
              <span className="cursor-pointer text-danger">-210</span>
            );
        },
      }
    ],
    []
  );

    return (
        <>
        <TableContainer
            columns={(columns || [])}
            data={(defaultTable || [])}
            isBordered={false}
            customPageSize={10}
            isPagination
            tableClass="table-centered align-middle table-nowrap table-striped mb-3"
            theadClass="text-muted table-light"
            SearchPlaceholder='Search Products...'
        />
        <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div className='d-flex align-items-center'>Parent List of <span className='text-muted fw-normal mx-2'>(lcbook247-prime)</span></div>
                <Button variant="light btn-sm" onClick={toggleList}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body>
                List
            </Modal.Body>
        </Modal>
        </>
    );
};

export default StatementTable;