import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { authServices } from 'Utils/auth/services'
import snackbarUtil from 'Utils/snackBarUtil'
import { BetLimits } from '.'

// Define types for your props
interface FancyItem {
    fancy_id: string;
    fancy_name: string;
    is_lock: boolean;
    LayPrice1: number;
    LaySize1: number;
    BackPrice1: number;
    BackSize1: number;
    GameStatus: string;
}

interface CombineFancyData {
    data: FancyItem[];
    fancy_category: string[];
}

interface Props {
    combineFancyData: CombineFancyData; // Use the defined type
    toggleMatchSetting: any;
    toggleBook: (id: string) => void;
    toggleBets: () => void;
    toggleFancyBook: () => void;
    setLock: Dispatch<SetStateAction<boolean>>;
    lock: boolean;
    toggleAbond: (id: string, name: string, marketName: string) => void;
    toggleNews: (market_id: any, name: any, marketName: string) => void;
    getFancyData: () => Promise<void>;
    limits: BetLimits | undefined;
    previousData: null | any;
    limitData:any
}

const FancyMarket: FC<Props> = ({
    previousData,
    limits,
    toggleAbond,
    getFancyData,
    toggleNews,
    combineFancyData,
    toggleMatchSetting,
    toggleBets,
    toggleBook,
    toggleFancyBook,
    setLock,
    lock,
    limitData
}) => {
    const updateLoclstatus = async (is_lock: boolean, fancy_id: string) => {
        const { response } = await authServices.updateLimits({
            fancy_id,
            values: {
                is_lock
            }
        });
        if (response?.status) {
            snackbarUtil.success(response.msg);
            getFancyData();
        } else {
            snackbarUtil.error(response.msg);
        }
    };

    const handleLock = (is_lock: boolean, fancy_id: string) => {
        updateLoclstatus(is_lock, fancy_id);
    };

    function convertToKandLForMax(number: number) {
        if (isNaN(number) || number == null) {
            return '0';
        }
        const sizeData = Number(number);
        const absNumber = Math.abs(sizeData);

        if (absNumber >= 10000000) {
            return (sizeData / 10000000).toFixed(1) + 'Cr';
        } else if (absNumber >= 100000) {
            return (sizeData / 100000).toFixed(1) + 'L';
        } else if (absNumber >= 1000) {
            return (sizeData / 1000).toFixed(1) + 'K';
        } else {
            return sizeData.toString();
        }
    }

    const [fancyData, setFancyData] = useState<{ [key: string]: { category: number; data: FancyItem[] } } | undefined>();
    const [fancyDataPrev, setFancyDataPrev] = useState<{ [key: string]: { category: number; data: FancyItem[] } } | undefined>();

    useEffect(() => {
        const categorizedData = combineFancyData?.data?.reduce((acc: any, item: any) => {
            const categoryKey = item.category; 
            const fancyCategoryName = categoryKey === 0 ? "Fancy" : combineFancyData.fancy_category[categoryKey];

            if (!acc[fancyCategoryName]) {
                acc[fancyCategoryName] = {
                    category: categoryKey,
                    data: []
                };
            }

            acc[fancyCategoryName].data.push(item);
            return acc;
        }, {});

        setFancyData(categorizedData);
    }, [combineFancyData]);

    useEffect(() => {
        const categorizedData = previousData?.data?.reduce((acc: any, item: any) => {
            const categoryKey = item.category; 
            const fancyCategoryName = categoryKey === 0 ? "Fancy" : previousData.fancy_category[categoryKey];

            if (!acc[fancyCategoryName]) {
                acc[fancyCategoryName] = {
                    category: categoryKey,
                    data: []
                };
            }

            acc[fancyCategoryName].data.push(item);
            return acc;
        }, {});

        setFancyDataPrev(categorizedData);
    }, [previousData]);

    return (
        <>
            {fancyData && Object.keys(fancyData)?.map((keys) => {
                return (
                    <Card key={keys} className='p-2 mb-2 ribbon-box border shadow-none'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center' style={{ paddingLeft: '65px' }}>
                                <div className="ribbon ribbon-primary ribbon-shape">{keys}</div>
                            </div>
                            <div className='d-flex justify-content-end align-items-center'>
                                <Button className='badge-gradient-success btn-sm border-0 ms-2' onClick={() => toggleBook("")}>Book</Button>
                                <Button className='badge-gradient-primary btn-sm border-0 ms-2' onClick={toggleBets}>Bets</Button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table className="table-borderless table-striped align-middle table-nowrap mb-0 mt-1">
                                <thead className='table-light'>
                                    <tr>
                                        <th scope="col" colSpan={2} className='py-2 px-1'></th>
                                        <th className='py-2 px-1'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <div className='px-2 textLay'>No</div>
                                                <div className='px-2 textBack'>Yes</div>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fancyData[keys]?.data?.map((fancys, id:number) => {
                                        const prevData =fancyDataPrev && fancyDataPrev[keys]?.data[id];

                                        const lay_blink = fancys?.LayPrice1 !== prevData?.LayPrice1?"lay_blink":"";
                                        const back_blink = fancys?.BackPrice1 !== prevData?.BackPrice1?"back_blink":"";
                                        const limit  =limitData && limitData[fancys?.fancy_id]

                                        return (
                                            <tr key={fancys.fancy_id}>
                                                <td className='w-100 p-1'>
                                                    <strong>{fancys?.fancy_name}</strong>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='text-muted me-2'>(0)</span>
                                                        <span className='text-danger cursor-pointer' onClick={toggleFancyBook}>0</span>
                                                    </div>
                                                </td>
                                                <td className='p-1'>
                                                    <div className='d-flex align-items-center'>
                                                        <Button variant="btn-ghost btn-sm p-0 ms-2" onClick={() => toggleMatchSetting(fancys?.fancy_id, "fancy_id")}><i className='ri-settings-4-line fs-xl'></i></Button>
                                                        <Button variant="btn-ghost btn-sm p-0 ms-2"><i className='bx bx-edit align-middle fs-xl'></i></Button>
                                                        <Button variant="btn-ghost btn-sm p-0 ms-2" onClick={() => handleLock(!fancys?.is_lock, fancys.fancy_id)}><i className={`fs-xl ${!fancys?.is_lock ? 'ri-lock-unlock-line' : 'ri-lock-line'}`}></i></Button>
                                                        <Button className='badge-gradient-danger btn-sm border-0 ms-2' onClick={() => toggleAbond(fancys.fancy_id, "fancy_id", "Fancy")}>Abond</Button>
                                                        <Button className='badge-gradient-info btn-sm border-0 ms-2' onClick={() => toggleNews(fancys.fancy_id, "fancy_id", "fancy")}>News</Button>
                                                    </div>
                                                </td>
                                                <td className='p-1'>
                                                    <div className='oddsSection d-flex position-relative'>
                                                        <div className={`oddBtn p-1 text-secondary lay ${lay_blink} lh-sm rounded text-center`}>
                                                            <strong className='fs-xs'>{fancys?.LayPrice1}</strong>
                                                            <p className='mb-0 fs-2xs'>{fancys.LaySize1}</p>
                                                        </div>
                                                        <div className={`oddBtn p-1 text-secondary back ${back_blink} lh-sm rounded text-center`}>
                                                            <strong className='fs-xs'>{fancys?.BackPrice1}</strong>
                                                            <p className='mb-0 fs-2xs'>{fancys?.BackSize1}</p>
                                                        </div>
                                                        {fancys?.GameStatus === "SUSPENDED" && (
                                                            <div className='position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold'>
                                                                {fancys?.GameStatus}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='fs-3xs'>
                                                        <div>Min: {convertToKandLForMax(limit?.session_min_stack ?? 0)}</div>
                                                        <div>Max: {convertToKandLForMax(limit?.session_max_stack ?? 0)}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                )
            })}
        </>
    );
}

export default FancyMarket;
