import BreadCrumb from "Common/BreadCrumb";
import { Container, Tab, Nav } from "react-bootstrap";
import Statement from "./Statement";
import ChipSummary from "./ChipSummary";

const Reports = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title="Reports" pageTitle="Dashboard" back />
                </div>

                <Tab.Container defaultActiveKey="Statement">
                    <Nav as="ul" variant="pills" className="arrow-navtabs nav-secondary mb-3">
                        <Nav.Item as="li" className="me-2"><Nav.Link eventKey="Statement" >Statement </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="ChipSummary" >Chip Summary </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="CasinoBets" >Casino Bets </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="Market" >Profit Loss </Nav.Link></Nav.Item>
                        {/* <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="Sport" >Sport P|L </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="QTech" >QTech P|L </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="FancyPL" >Fancy P|L </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="FancyM" >Fancy Stake (M) </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="FancyU" >Fancy Stake (U) </Nav.Link></Nav.Item> */}
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="OpenBets" >Open Bets </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="SettledBets" >Settled Bets </Nav.Link></Nav.Item>
                        <Nav.Item as="li" className="mx-2"><Nav.Link eventKey="NewsUpdate" >News Update </Nav.Link></Nav.Item>
                    </Nav>
                    <Tab.Content className="text-muted">
                        <Tab.Pane eventKey="Statement">
                            <Statement />
                        </Tab.Pane>
                        <Tab.Pane eventKey="ChipSummary">
                            <ChipSummary />
                        </Tab.Pane>
                        <Tab.Pane eventKey="CasinoBets">Casino Bets</Tab.Pane>
                        <Tab.Pane eventKey="Market">Profit Loss</Tab.Pane>
                        {/* <Tab.Pane eventKey="Sport">Sport P|L</Tab.Pane>
                        <Tab.Pane eventKey="QTech">QTech P|L</Tab.Pane>
                        <Tab.Pane eventKey="FancyPL">Fancy P|L</Tab.Pane>
                        <Tab.Pane eventKey="FancyM">Fancy Stake (M)</Tab.Pane>
                        <Tab.Pane eventKey="FancyU">Fancy Stake (U)</Tab.Pane> */}
                        <Tab.Pane eventKey="OpenBets">Open Bets</Tab.Pane>
                        <Tab.Pane eventKey="SettledBets">Settled Bets</Tab.Pane>
                        <Tab.Pane eventKey="NewsUpdate">News Update</Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>
        </div>
    );
};

export default Reports;