import axios, { AxiosRequestHeaders } from "axios";
import snackBarUtil from "./snackBarUtil";
import { errorRef, setErrorRef } from "index";

export interface ApiResource {
  URL: string;
  METHOD?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
}

interface Query {
  [key: string]: string | number | boolean | string[] | number[];
}

export interface ApiServiceInterface {
  resource: ApiResource;
  params?: Query;
  pathVars?: Query;
  data?: any;
  noAuth?: boolean;
  betfair?: boolean;
  headers?: AxiosRequestHeaders;
  customToken?: string;
}

interface Error {
  message: string[];
}

interface ApiResponse {
  response: any;
  error: Error | null;
}

const filterQuery = (query: Query) => {
  const newQuery: Query = {};
  Object.keys(query).forEach((item) => {
    if (Array.isArray(query[item])) {
      newQuery[item] = query[item].toString();
    } else {
      newQuery[item] = query[item];
    }
  });
  return newQuery;
};

const apiService: (arg: ApiServiceInterface) => Promise<any> = async ({
  resource,
  data = {},
  params = {},
  headers = {},
  pathVars = {},
  noAuth = false,
  customToken,
}) => {
  const { METHOD, URL } = resource;
  const token = localStorage.getItem("token");
  if (!token) {
    return;
  }
  let url = URL;
  Object.keys(pathVars).forEach((key) => {
    if (url) url = url.replace(":" + key, pathVars[key].toString());
  });
  let config;
  const baseURL = process.env.REACT_APP_API_BASEURL;

  try {
    config = {
      method: METHOD || "GET",
      baseURL,
      url,
      data,
      params: filterQuery(params),
      headers: {
        ...headers,
        ...(Boolean(token && !noAuth)
          ? { Authorization: `Bearer ${customToken || token}` }
          : {}),
      },
    };
  } catch (errors: any) {
    console.log(errors?.response, "error");
  }

  if (config) {
    return axios(config);
  }
};

const apiHandler: (arg: ApiServiceInterface) => Promise<ApiResponse> = async (
  args
) => {
  let result: any = {};
  await apiService(args)
    .then((response) => {
      if (response) {
        if (errorRef && setErrorRef) {
          setErrorRef(false);
        }
        if (response?.status === false) {
          if (response.status) {
            snackBarUtil.success(response.msg);
          } else {
            if(!response?.msg?.includes("No live data found!") && !response?.msg?.includes("It must have a valid ObjectId"))
            snackBarUtil.error(response.msg);
          }
          if (response?.logout) {
            localStorage.removeItem("token");
            localStorage.removeItem("adminDetails");
            localStorage.removeItem("adminRefreshToken");
            localStorage.removeItem("operatorId");
            localStorage.removeItem("userId");
            window.location.replace("/login");
          }
        } else {
          result["response"] = response;
        }
      }
    })
    .catch((error) => {
      result["error"] = error.response?.data;
      if (error.code === "ERR_NETWORK") {
        if (!errorRef) {
          setErrorRef(true);
        }
      } else if (error.response?.status === 401) {
        result.error = {};
        window.location.replace("/");
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
          localStorage.removeItem("adminDetails");
          localStorage.removeItem("adminRefreshToken");
          localStorage.removeItem("operatorId");
          localStorage.removeItem("userId");
        }
      } else {
        if (errorRef && setErrorRef) {
          setErrorRef(false);
        }
      }
    });

  return result;
};

const apiSnackbarNotifications: (
  arg: ApiResponse
) => Promise<ApiResponse> = async (args) => {
  if (args.error) {
    const { message } = args.error;
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        if (typeof msg === "string") {
          snackBarUtil.error(msg);
        } else {
          console.warn("Invalid message type in error array:", msg);
        }
      });
    } else if (typeof message === "string") {
      snackBarUtil.error(message);
    } else {
      console.warn("Unexpected error message format:", message);
    }
  }

  // Check for response status and notify
  if (!args?.response?.status) {
    const errorMsg = args?.response?.msg;
    if (typeof errorMsg === "string") {
      snackBarUtil.error(errorMsg);
    } else {
      console.warn("Invalid message in response:", errorMsg);
    }
  } else if (typeof args?.response?.message === "string") {
    snackBarUtil.success(args.response.message);
  } else {
    console.warn("Unexpected response message format:", args.response?.message);
  }

  return args;
};

// Modified apiWithSnackbar to suppress error notifications
const apiWithSnackbar: (
  arg: ApiServiceInterface
) => Promise<ApiResponse> = async (args) => {
  const result = await apiHandler(args);
  return apiSnackbarNotifications(result);
};

// Keep apiWithErrorSnackbar to handle errors as before
const apiWithErrorSnackbar: (
  arg: ApiServiceInterface
) => Promise<ApiResponse> = async (args) => {
  const result = await apiHandler(args);
  if (result?.error) {
    const { message } = result.error;
    if (typeof message === "object") {
      message.forEach((msg) => {
        if (typeof msg === "string") {
          snackBarUtil.error(msg);
        } else {
          console.warn("Invalid message type in error array:", msg);
        }
      });
    } else {
      snackBarUtil.error(message);
    }
  }
  return result;
};

export { apiService, apiHandler, apiWithErrorSnackbar, apiWithSnackbar };
