import { Form, Row, Col, Card, Button } from "react-bootstrap";
import { Dispatch, FC, SetStateAction } from "react";

interface ToggleStates {
  [key: string]: boolean;
}

interface Props {
  userData: DataRes | undefined;
  setUserPayload: Dispatch<
    SetStateAction<{
      partnership: number;
      domain: string;
      domain_name: string;
      name: string;
      user_name: string;
      password: string;
      user_type_id: string;
      parent_id: string;
      point: number;
      exposure_limit: number;
      belongs_to_credit_reference: number;
      credit_reference: number;
      belongs_to: string;
      title: string;
      session_commission: number;
      match_commission: number;
    }>
  >;
  userPayload: {
    partnership: number;
    domain: string;
    domain_name: string;
    name: string;
    user_name: string;
    password: string;
    user_type_id: string;
    parent_id: string;
    point: number;
    exposure_limit: number;
    belongs_to_credit_reference: number;
    credit_reference: number;
    belongs_to: string;
    title: string;
    session_commission: number;
    match_commission: number;
  };
}

const CommissionSetting: FC<Props> = ({ userData, setUserPayload, userPayload }) => {

  // Function to handle the plus/minus for match commission
  const updateMatchCommission = (amount: number) => {
    setUserPayload(prev => ({
      ...prev,
      match_commission: Math.max(0, Math.min(100, prev.match_commission + amount)) // Restricting between 0 and 100
    }));
  };

  // Function to handle the plus/minus for session commission
  const updateSessionCommission = (amount: number) => {
    setUserPayload(prev => ({
      ...prev,
      session_commission: Math.max(0, Math.min(100, prev.session_commission + amount)) // Restricting between 0 and 100
    }));
  };

  return (
    <Card>
      <Card.Body>
        <Form action="#">
          <Row className="g-3">
            <h5 className="text-primary mb-0">Commission Setting</h5>
            <Col>
              <div className="fs-md mb-2">Market Commission</div>
              <div className="input-step step-primary">
                <Button className="minus" onClick={() => updateMatchCommission(-1)}>-</Button>
                <Form.Control
                  type="number"
                  className="product-quantity"
                  value={userPayload.match_commission} // Controlled by state
                  min="0"
                  max="100"
                  readOnly
                  name="match_commission"
                />
                <Button className="plus" onClick={() => updateMatchCommission(1)}>+</Button>
              </div>
            </Col>
            <Col>
              <div className="fs-md mb-2">Session Commission</div>
              <div className="input-step step-primary">
                <Button className="minus" onClick={() => updateSessionCommission(-1)}>-</Button>
                <Form.Control
                  name="session_commission"
                  type="number"
                  className="product-quantity"
                  value={userPayload.session_commission} // Controlled by state
                  min="0"
                  max="100"
                  readOnly
                />
                <Button className="plus" onClick={() => updateSessionCommission(1)}>+</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CommissionSetting;
