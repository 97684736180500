import React, { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import News from 'Common/News';
import Sports from 'Common/Sports';
import DashboardTable from 'Common/Tables/DashboardTable';
import { authServices } from 'Utils/auth/services';
import DashboardTableAll from 'Common/Tables/DashboardTableAll';
import { useNavigate } from 'react-router-dom';
import snackbarUtil from 'Utils/snackBarUtil';
import HrGrTable from 'Common/Tables/HrGrTable';


export interface sportListProps{
    name: string,
    sport_id: string,
    is_active: boolean,
    providerCode: null,
    is_live_sport: number
}
interface Runner {
    selectionId: number;
    status: string;
    ex: {
      availableToBack: Array<{ size: string; price: string }>;
      availableToLay: Array<{ size: string; price: string }>;
    };
  }
  
 export  interface Match {
    sport_id: string;
    sport_name: string;
    series_id: string;
    series_name: string;
    match_id: string;
    match_name: string;
    market_id: string;
    status: string;
    inplay: boolean;
    match_date: string;
    marketIds: string[];
    is_active: number;
    enable_fancy: number;
    fancy_count: number;
    market_count: number;
    bookmaker_count: number;
    runners: Runner[];
  }
  
 export interface GroupedData {
    [sport_id: string]: Match[];
  }
 export interface GroupedDataName {
    [sport_name: string]: Match[];
  }

const Dashboard = () => {
    const [sportList, setSportList] = useState<sportListProps[]>([]);
    const [sportId, setSportId] = useState<number>(0)
    const [active, setActive] = useState<number>(0)
    const [sportData, setSportData] = useState<GroupedData>();
    const [sportDataName, setSportDataName] = useState<GroupedDataName>();
    const [updatePaylod, setUpdatePaylod] = useState({
        matchId:"",
        isActive:0
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const token  = localStorage.getItem("token");
    const nav = useNavigate();

    if(!token){
        nav('/login')
    }

      const customOrder = [
        "Cricket",
        "Soccer",
        "Tennis",
        "Horse Racing",
        "Greyhound Racing", 
        "Share Market",
        "Casino",
        "QTech",
        "1x2 Gaming",
        "AvatarUX",
        "BB Games"
       
    ];

    const fetchData = async()=>{
        const {response} = await authServices.getSportList();
        // setSportList(response?.data)
        const data =  response?.data?.sort((a: { name: string; }, b: { name: string; }) => {
            const indexA = customOrder.indexOf(a.name);
            const indexB = customOrder.indexOf(b.name);
            
           
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            
            return indexA - indexB;
        });
        setSportList(data)
        
    }


    const fetchSportData = async()=>{
        const {response} = await authServices.getSportData({
            combine:true,   
        });

        const groupedData = groupBySportId(response?.data);
        const groupedDataName = groupBySportName(response?.data);

        setSportData(groupedData)
        setSportDataName(groupedDataName)
    }

    const groupBySportId = (matches: Match[]): GroupedData => {
        return matches.reduce((acc, match) => {
          const { sport_id } = match;
          if (!acc[sport_id]) {
            acc[sport_id] = [];
          }
          acc[sport_id].push(match);
          return acc;
        }, {} as GroupedData);
      };


      const groupBySportName = (matches: Match[]): GroupedData => {
        const groupedData = matches.reduce((acc, match) => {
          const { sport_name } = match;
          if (!acc[sport_name]) {
            acc[sport_name] = [];
          }
          acc[sport_name].push(match);
          return acc;
        }, {} as GroupedData);
      
        const sortedGroupedData = Object.keys(groupedData)
          .sort() 
          .reduce((sortedAcc, sportName) => {
            sortedAcc[sportName] = groupedData[sportName];
            return sortedAcc;
          }, {} as GroupedData);
      
        return sortedGroupedData;
      };
    
    useEffect(() => {
        fetchSportData();
        fetchData();
        const intervalId = setInterval(() => {
            fetchSportData();
        }, 10000);
        return () => clearInterval(intervalId);
    }, []);


    const getEnabaledFancy = async (matchedId: string, enable_fancy: number) => {
        try {
            const { response } = await authServices.getEnabaledFancy({ match_id: matchedId, enable_fancy : enable_fancy === 1?0:1 });
            console.log('API response:', response);
            if(response?.status){
                snackbarUtil.success(response.msg)
                fetchSportData();
            }else{
                snackbarUtil.error(response.msg);
            }

        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };

    const updateMatchStatus = async (updatePaylod: { matchId: string; isActive: number; }) => {
        try {
            const { response } = await authServices.updateMatchStatus({ match_id: updatePaylod.matchId, is_active:updatePaylod.isActive === 1?0:1 });
            console.log('API response:', response);
            if(response?.status){
                snackbarUtil.success(response.msg);
                fetchSportData();
                handleClose();
            }else{
                snackbarUtil.error(response.msg);
            }
        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };
    const updateEvent = async (updatePaylod: { matchId: string; isActive: number; }) => {
        try {
            const { response } = await authServices.updateEvent({ event: "match", filter:{
                match_id:updatePaylod.matchId
            }, update:{
                is_visible:false
            } });
            if(response?.status){
                snackbarUtil.success(response.msg)
                handleClose();
            }else{
                snackbarUtil.error(response.msg)
            }
        } catch (error) {
            console.error('Error fetching enabled fancy data:', error);
        }
    };


    const handleActiveDeactive = ()=>{
        updateMatchStatus(updatePaylod);
        updateEvent(updatePaylod)
    }

    console.log(sportData && sportData[active], "433943394339")

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dashboard" pageTitle="Steex" />
                    <Sports sportList={sportList} setSportId={setSportId} setActive={setActive} active={active}/>
                   
                    {
                        (active === 7  || active === 4339 ) ? <HrGrTable sportData={sportData && sportData[active]} active={active}/>:<>
                         {
                        active === 0 || active === 200 ?
                        <DashboardTableAll active={active}  setUpdatePaylod={setUpdatePaylod} setShow={setShow}  getEnabaledFancy={getEnabaledFancy}  sportData={sportDataName} sportId={sportId}/>
                        :<DashboardTable setUpdatePaylod={setUpdatePaylod} setShow={setShow}  getEnabaledFancy={getEnabaledFancy} sportData={sportData} sportId={sportId}/>
                    }
                        </>
                    }
                    
                </Container>
            </div>
            <Modal  show={show}>
            <Modal.Body>
                <h5 className='lg text-center'>Are you sure want to deactive Match ?</h5>
            </Modal.Body>

            <Modal.Footer>
                <Button size='sm' variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button size='sm' variant="primary" onClick={handleActiveDeactive}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default Dashboard;
