import MatchList from 'Common/Modals/MatchList';
import MatchSetting from 'Common/Modals/MatchSetting';
import TandCModal from 'Common/Modals/TandCModal';
import { FC, useState } from 'react';
import { Card, Button, Badge, Modal, ListGroup, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import { BetLimits } from '.';
import { useLocation } from 'react-router-dom';
import { Market } from './DetailsLeft';
import moment from 'moment'
import snackbarUtil from 'Utils/snackBarUtil';
import BlinkDot from 'Common/BlinkDot';

interface Props{
    state:string
    setShow: React.Dispatch<React.SetStateAction<boolean>>,
    id: string | undefined,
    limits: BetLimits | undefined
    getLimit: () => Promise<void>,
    setLimits: React.Dispatch<React.SetStateAction<BetLimits | undefined>>
    conbineData: Market | undefined
    getMatchDetails: () => Promise<void>
}



  

const DetailsTopBar:FC<Props> = ({getMatchDetails, state, setShow, id, setLimits, limits, getLimit, conbineData}) => {
    const[showMatches, setMatches] = useState(false);
    const[matchFancy, setMatchFancy] = useState(false);
    const[showTandC, setTandC] = useState(false);
    const[manualInplay, setManualInplay] = useState(false);
    const[matchLock, setMatchLock] = useState(false);
    

    const {state:name} = useLocation();
    const toggleMatches = () => {
        setMatches(!showMatches)
    }
    const toggleTandC = () => {
        setTandC(!showTandC)
    }
    const[matchSetting, setMatchSetting] = useState(false);

    const toggleMatchSetting = () => {
        setMatchSetting(!matchSetting);
        getLimit();
    }
    const handleMathcedFancy = ()=>{
        setMatchFancy(!matchFancy);
        setShow(true);
    }


    const updateLimits = async(is_lock:boolean)=>{
        const {response} = await authServices.updateLimits({
            match_id:id|| "",
            values:{
                is_lock
            }
        })

        if(response?.status){
            snackbarUtil.success(response?.msg);
            getMatchDetails();
        }
    }
   

    const handleMatchLock = (is_lock:boolean)=>{
        updateLimits(is_lock)
    }


    return (
        <>
            <Card className='p-2 mb-2'>
                <div className='d-flex align-items-sm-center justify-content-between flex-column flex-sm-row'>
                    <div>
                        <div className='d-flex align-items-center'>
                            <Button variant='btn-ghost-dark py-1 px-2 me-1'><i className='ri-arrow-left-line fs-xl'></i></Button>
                            <div>
                                <h5 className='mb-1 position-relative'>{state}</h5> 
                                <span className='text-muted'>{moment(conbineData?.match_date).format('DD-MM-YY hh:mm A')}</span>
                            </div>
                        </div>
                        {/* <Badge bg='success' className="badge-label ms-3 ps-0"> <i className="mdi mdi-circle-medium"></i> Inplay</Badge> */}
                    </div>
                    <div className='mt-2 mt-lg-0 d-flex' style={{minWidth: '220px'}}>
                        {/* <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {manualInplay ? 'Inplay On' : 'Inplay Off'} </Tooltip>}>
                            <Button variant='btn-ghost py-1 px-2' onClick={()=>setManualInplay(!manualInplay)}><i className={`fs-3xl ${manualInplay ? 'ri-pause-line' : 'ri-play-line'}`}></i></Button>
                        </OverlayTrigger> */}
                        <BlinkDot className='mt-1' />
                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchFancy ? 'Fancy On' : 'Fancy Off'} </Tooltip>}>
                            <Button variant='btn-ghost py-1 px-2' onClick={handleMathcedFancy}><span className={`fs-xl ${matchFancy ? 'text-success' : 'text-danger'}`}>F</span></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {'Terms & Conditions'} </Tooltip>}>
                            <Button variant='btn-ghost py-1 px-2 me-1' onClick={toggleTandC}><i className='ri-information-line fs-xl'></i></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {'Market Setting'} </Tooltip>}>
                            <Button variant='btn-ghost py-1 px-2 me-1' onClick={toggleMatchSetting}><i className='ri-settings-4-line fs-xl'></i></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {conbineData?.is_lock ? 'Lock' : 'UnLock'} </Tooltip>}>
                            <Button variant='btn-ghost py-1 px-2 me-1' onClick={()=>handleMatchLock(!conbineData?.is_lock)}><i className={`fs-xl ${matchLock ? 'ri-lock-line' : 'ri-lock-unlock-line'}`}></i></Button>
                        </OverlayTrigger>
                        <Button variant='subtle-primary py-1 px-2 me-1' onClick={toggleMatches}><i className='ri-play-list-add-line fs-xl'></i></Button>
                    </div>
                </div>
            </Card>

            <TandCModal show={showTandC} clickHandler={toggleTandC} />

            <MatchSetting marketId="match_id" shoeOdss={true} name={name} fancyShow={true} show={matchSetting} clickHandler={toggleMatchSetting} id={id} getLimit={getLimit}  limits={limits}/>  

            <MatchList show={showMatches} clickHandler={toggleMatches} />
        </>
    );
};

export default DetailsTopBar;