import BreadCrumb from "Common/BreadCrumb";
import ToggleSwitch from "Common/ToggleSwitch";
import { useCallback, useEffect, useState } from "react";
import { Container, Card, Form, Button, Table, Pagination, Modal, Dropdown, Accordion, useAccordionButton, OverlayTrigger, Tooltip, Popover, ListGroup, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import LimitSetting from "./LimitSetting";
import moment from "moment";

interface CustomToggleProps {
    eventKey?: any;
    children?: any;
}



const ImportMarket = () => {
    const [showOn, setShowOn] = useState(false);
    const [matchVisible, setMatchVisible] = useState(false);
    const [marketSetting, setMarketSetting] = useState(false);
    const [unmatchedBet, setUnmatchedBet] = useState(false);
    const [matchLock, setMatchLock] = useState(false);
    const [showTvScore, setTvScore] = useState(false);
    const [deleteMatch, setDeleteMatch] = useState(false);
    const [showPassword, setPassword] = useState(false);
    const [sportSeries, setSportSeries] = useState<{ [key: string]: seriesRes[] }>({});
    const [sportMatches, setSportMatches] = useState<{ [key: string]: MatchesRes[] }>({});
    const [sportName, setSportName] = useState<sportList[]>([]);
    const [sportIds, setSportids] = useState<string>("");
    const [sportNameMarket, setSportiNameMarket] = useState<string>("");
    const [limits, setLimits] = useState<LimitResponse>();
    const [marketName, setMarkrtName] = useState<string>("");
    const [unmatchedBetVisible, setUnmatchedBetVisible] = useState<{ [key: string]: boolean }>({});
    const [unMatedBet, setUnMatchedBet] = useState<boolean>();
    const [marketData, setMarketData] = useState<MatchesRes[]>([]);



    const getUpdateEvent = async (is_visible: boolean, marketName: any) => {
        const { response } = await authServices?.updateEvent({
            event: "sport",
            filter: {
                [marketName]: sportIds
            },
            update: {
                is_visible,
            }
        })
        if (response?.status) {
            snackbarUtil.success(response?.data);
            getSport();
        }
    }



    const toggleVisible = (is_visible: boolean, sportName?: string) => {
        setMatchVisible(!matchVisible);
        getUpdateEvent(is_visible, sportName);
    }
    const getLimit = async () => {
        const { response } = await authServices?.getLimits({
            sport_id: sportIds
        })
        setLimits(response?.data);
        setUnMatchedBet(response?.data?.limites?.unmatch_bet_allowed)
    }
    const toggleMarketSetting = (name: string, mrktName: string) => {
        setMarketSetting(!marketSetting);
        setMarkrtName(mrktName)
        setSportiNameMarket(name);
        getLimit();
    }
    const toggleUnmatchedBet = (sportId: string) => {
        setUnmatchedBetVisible((prev) => ({
            ...prev,
            [sportId]: !prev[sportId],
        }));
        getLimit();
    };
    const toggleMatchLock = () => {
        setMatchLock(!matchLock)
    }


    const getSport = async () => {
        const { response } = await authServices.getSportListData({});
        setSportName(response?.data);
    };

    const handleSportSeriesData = useCallback(async (sportId: string) => {
        if (!sportSeries[sportId]) {
            try {
                const { response } = await authServices.getSeriesData({ sport_id: sportId });
                setSportSeries(prev => ({ ...prev, [sportId]: response?.data || [] }));
                setSportids(sportId);
            } catch (error) {
                console.error("Error fetching series data:", error);
            }
        }
        getLimit();

    }, [sportSeries]);

    const getMatchesData = useCallback(async (sport_id: string, series_id: string) => {
        try {
            const { response } = await authServices.getMatchesData({ sport_id, series_id });
            setSportMatches(prev => ({ ...prev, [series_id]: response?.data || [] }));
        } catch (error) {
            console.error("Error fetching matches:", error);
        }
    }, []);



    // Update Sport Status

    const userDetails = localStorage.getItem("adminDetails");
    const userId = userDetails && JSON.parse(userDetails)

    const getUpdateStatus = async (is_active: number) => {
        const { response } = await authServices.getUpdateSportServices({
            is_active: is_active === 0 ? 1 : 0,
            sport_id: sportIds,
            user_typeId: userId?.user_type_id,
            userid: userId?._id
        })

        if (response?.status) {
            snackbarUtil.success(response.msg);
            getSport();
        }
    }
    const handleMatches = (seriesId: string, sportId: string) => {
        getMatchesData(sportId, seriesId);
    };

    useEffect(() => {
        getSport();
    }, []);

    const handleActiveSport = (is_active: number, sport_id: string) => {
        getUpdateStatus(is_active)
    }

    const getUpdateLimits = async (data: any) => {
        let payload;

        payload = data;

        const { response } = await authServices.updateLimits({
            [marketName]: sportIds || "",
            values: payload
        })

        if (response.status) {
            snackbarUtil.success(response.msg);

            getLimit();
        } else {
            snackbarUtil.error(response.msg)
        }
    }


    const getMarketData = async(match_id:string) =>{
        const {response} = await authServices.getMarketData({
            match_id,
            sport_id:sportIds
        });

        setMarketData(response?.data)
    }


    const handleMarketData = (matchid:string)=>{
        getMarketData(matchid)
    }





    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2">
                    <BreadCrumb title="Import Market" pageTitle="Dashboard" back />
                </div>
                <Accordion defaultActiveKey='0' className="custom-accordion-border accordion-border-box accordion-dark">
                    {
                        sportName?.map((sport) => {
                            return (
                                <Accordion.Item key={sport?.name} eventKey={sport?.name} className="position-relative">
                                    <Accordion.Header onClick={() => handleSportSeriesData(sport?.sport_id)}>{sport?.name}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="mb-2 d-flex align-items-center">
                                            <ToggleSwitch On='On' Off='Off' className="me-2" id='match' onChange={() => handleActiveSport(sport?.is_active, sport?.sport_id)} checked={sport?.is_active ? true : false} />

                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Market Setting </Tooltip>}>
                                                <Button variant='btn-ghost py-1 px-2 me-1' onClick={() => toggleMarketSetting(sport?.name, "sport_id")}><i className='ri-settings-3-fill fs-xl'></i></Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {!sport?.is_visible ? 'Invisible' : 'Visible'} </Tooltip>}>
                                                <Button variant='btn-ghost py-1 px-2 me-2' onClick={() => toggleVisible(!sport?.is_visible, "sport_id")}><i className={`fs-xl ${!sport?.is_visible ? 'ri-eye-off-fill text-danger' : 'ri-eye-fill text-success'}`}></i></Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger trigger="click" placement="right" show={!!unmatchedBetVisible[sport.sport_id]}
                                                overlay={
                                                    <Popover id="popover-positioned-top">
                                                        <Popover.Header as="h3">Cricket Settings : <span>(lcbook247-prime)</span></Popover.Header>
                                                        <Popover.Body>
                                                            <div className="d-flex align-items-center">
                                                                <strong className="fs-lg me-2">UnMatched Bet Allowed </strong>
                                                                <ToggleSwitch On="Yes" Off="No" checked={unMatedBet} onChange={(e:any) => setUnMatchedBet(e.target.value)} />
                                                            </div>

                                                            <div className="border-top mt-3 pt-3 text-end">
                                                                <Button variant="subtle-success">Save</Button>
                                                                <Button variant="subtle-dark" className="ms-2" onClick={() => toggleUnmatchedBet(sport.sport_id)}>Cancel</Button>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                <Button variant="subtle-secondary" className="p-1" onClick={() => toggleUnmatchedBet(sport.sport_id)}>UA</Button>
                                            </OverlayTrigger>

                                            <div className="ms-3 w-25">
                                                <div className="form-icon">
                                                    <Form.Control type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Series.." />
                                                    <i className="ri-search-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                            {
                                                sportSeries[sport.sport_id]?.map((series) => {
                                                    return (
                                                        <Accordion.Item key={series?.series_id} onClick={() => handleMatches(series?.series_id, series?.sport_id)} eventKey={series?.name} className="position-relative">
                                                            <Accordion.Header>{series?.name} <span className="mx-2 text-muted">|</span> <span className="">{series?.match_count}</span> <span className="mx-2 text-muted">|</span> <span className="">{series?.series_id}</span>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="mb-2 d-flex align-items-center">
                                                                    <ToggleSwitch On='On' Off='Off' className="me-2" id='match' onChange={() => setShowOn(!showOn)} checked={showOn ? true : false} />

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Market Setting </Tooltip>}>
                                                                        <Button variant='btn-ghost py-1 px-2 me-1'
                                                                        // onClick={toggleMarketSetting}
                                                                        ><i className='ri-settings-3-fill fs-xl'></i></Button>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchVisible ? 'Invisible' : 'Visible'} </Tooltip>}>
                                                                        <Button variant='btn-ghost py-1 px-2 me-2' onClick={() => toggleVisible(series?.is_visible, "sport_id")}><i className={`fs-xl ${matchVisible ? 'ri-eye-off-fill text-danger' : 'ri-eye-fill text-success'}`}></i></Button>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                        <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                    </OverlayTrigger>

                                                                    <div className="ms-2 w-25">
                                                                        <div className="form-icon">
                                                                            <Form.Control type="text" className="form-control form-control-icon rounded-pill" placeholder="Search Match.." />
                                                                            <i className="ri-search-line"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <Accordion className="custom-accordion-border accordion-border-box accordion-dark">
                                                                    {
                                                                        sportMatches[series?.series_id]?.map((matches)=>{
                                                                            return(
                                                                                <Accordion.Item eventKey={matches?.match_id} className="position-relative">
                                                                                <Accordion.Header onClick={()=>handleMarketData(matches?.match_id)}>{matches?.match_name} ({matches?.country_code}) <span className="mx-2 text-muted">|</span> <span className="">{matches?.match_id}</span> <span className="mx-2 text-muted">|</span> <span className="">{moment(matches.match_date)?.format("DD-MM-YYYY, HH:MM:SS, A")}</span>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div className="d-flex align-items-center mb-2">
                                                                                        <ToggleSwitch On="On" Off="Off" onChange={() => setShowOn(!showOn)} />
        
                                                                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Delete Match </Tooltip>}>
                                                                                            <Button variant='btn-ghost py-1 px-2 ms-2' onClick={() => setDeleteMatch(!deleteMatch)}><i className='ri-delete-bin-3-fill fs-xl'></i></Button>
                                                                                        </OverlayTrigger>
        
                                                                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchLock ? 'Lock' : 'UnLock'} </Tooltip>}>
                                                                                            <Button variant='btn-ghost py-1 px-2 me-1' onClick={toggleMatchLock}><i className={`fs-xl ${matchLock ? 'ri-lock-fill' : 'ri-lock-unlock-fill'}`}></i></Button>
                                                                                        </OverlayTrigger>
        
                                                                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                                            <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                                        </OverlayTrigger>
        
                                                                                        <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Create </Tooltip>}>
                                                                                            <Button variant='subtle-danger py-1 px-2 me-2'>C</Button>
                                                                                        </OverlayTrigger>
        
                                                                                        <Dropdown drop="start" className="ms-3">
                                                                                            <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                <i className="fs-lg ri-more-2-fill"></i>
                                                                                            </Dropdown.Toggle>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item className="border-bottom py-2">Market Setting</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2">Unmatch Bet Allowed</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2 text-success">Fancy</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2">Visible</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2">Manual Inplay</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2" onClick={() => setTvScore(!showTvScore)}>Update TV & Score</Dropdown.Item>
                                                                                                <Dropdown.Item className="border-bottom py-2">Route</Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </div>
                                                                                {
                                                                                    marketData?.map((market)=>{
                                                                                        return(
<ListGroup className="list-group-fill-success">
                                                                                        <ListGroup.Item as='a' className="list-group-item-action">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <strong className="me-3">{market?.name} <span className="mx-2">|</span> <span className="text-muted">({market?.market_id})</span> <span className="mx-2">|</span> <span className="text-muted">({market?.centralId})</span></strong>
        
                                                                                                <ToggleSwitch On="On" Off="Off" onChange={() => setShowOn(!showOn)} />
        
                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Delete Match </Tooltip>}>
                                                                                                    <Button variant='btn-ghost py-1 px-2 ms-2'
                                                                                                    //  onClick={toggleMarketSetting}
                                                                                                    ><i className='ri-delete-bin-3-fill fs-xl'></i></Button>
                                                                                                </OverlayTrigger>
        
                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> {matchLock ? 'Lock' : 'UnLock'} </Tooltip>}>
                                                                                                    <Button variant='btn-ghost py-1 px-2 me-1' onClick={toggleMatchLock}><i className={`fs-xl ${matchLock ? 'ri-lock-fill' : 'ri-lock-unlock-fill'}`}></i></Button>
                                                                                                </OverlayTrigger>
        
                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Active </Tooltip>}>
                                                                                                    <Button variant='subtle-success py-1 px-2 me-2'>A</Button>
                                                                                                </OverlayTrigger>
        
                                                                                                <OverlayTrigger placement='top' overlay={<Tooltip id="tooltip-top"> Create </Tooltip>}>
                                                                                                    <Button variant='subtle-danger py-1 px-2 me-2'>C</Button>
                                                                                                </OverlayTrigger>
        
                                                                                                <Dropdown drop="start" className="ms-auto">
                                                                                                    <Dropdown.Toggle variant="primary" className="e-caret-hide btn-sm">
                                                                                                        <i className="fs-lg ri-more-2-fill"></i>
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu>
                                                                                                        <Dropdown.Item className="border-bottom py-2">Market Setting</Dropdown.Item>
                                                                                                        <Dropdown.Item className="border-bottom py-2">Unmatch Bet Allowed</Dropdown.Item>
                                                                                                        <Dropdown.Item className="border-bottom py-2 text-success">Fancy</Dropdown.Item>
                                                                                                        <Dropdown.Item className="border-bottom py-2">Visible</Dropdown.Item>
                                                                                                        <Dropdown.Item className="py-2">Update News</Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </div>
                                                                                        </ListGroup.Item>
                                                                                    </ListGroup>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                  
                                                                                    
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                   

                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })
                                            }


                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </Container>

            <LimitSetting marketName={marketName} limits={limits} name={sportNameMarket} sportIds={sportIds} getLimit={getLimit} show={marketSetting} clickHandler={toggleMarketSetting} />

            <Modal show={showTvScore}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <div>Update TV & Score <span className='text-muted fw-normal'>(Dolphins v Titans)</span></div>
                    <Button variant="light btn-sm" onClick={() => setTvScore(!showTvScore)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating">
                        <Form.Control
                            name="remark"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="PremiumTV"
                            placeholder="Premium TV"
                        />
                        <Form.Label htmlFor="Content">Premium TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="remark"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="NormalTV"
                            placeholder="Normal TV"
                        />
                        <Form.Label htmlFor="Content">Normal TV</Form.Label>
                    </div>
                    <div className="form-floating mt-3">
                        <Form.Control
                            name="remark"
                            as="textarea"
                            rows={2}
                            style={{ height: 'auto' }}
                            id="Score"
                            placeholder="Score"
                        />
                        <Form.Label htmlFor="Content">Score</Form.Label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="subtle-primary">Update</Button>
                    <Button variant="subtle-dark" onClick={() => setTvScore(!showTvScore)}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteMatch} onHide={() => setDeleteMatch(!deleteMatch)}>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    Delete Match
                    <Button variant="light btn-sm" onClick={() => setDeleteMatch(!deleteMatch)}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-floating">
                        <Form.Control
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                        />
                        <Form.Label>Enter Password</Form.Label>
                        <Button
                            variant="link"
                            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPassword(!showPassword)}
                        >
                            <i className="ri-eye-fill align-middle"></i>
                        </Button>
                    </div>

                    <div className="form-check mt-3">
                        <Form.Check type="checkbox" id="formCheck" defaultChecked />
                        <Form.Label className="form-check-label" htmlFor="formCheck">
                            Do you want to delete all market
                        </Form.Label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="subtle-danger">Delete</Button>
                    <Button variant="subtle-dark" onClick={() => setDeleteMatch(!deleteMatch)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ImportMarket;