import HistoryModal from "Common/Modals/HistoryModal";
import { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

interface ChipSummaryTableProps {
  type : string;
}

const ChipSummaryTable = ({type} : ChipSummaryTableProps) => {
    const[showList, setList] = useState(false)
    const[showHistory, setHistory] = useState(false)
    const[showSettlement, setSettlement] = useState(false)
    
    const toggleList = () => {
      setList(!showList)
    }
    const toggleHistory = () => {
      setHistory(!showHistory)
    }
    const toggleSettlement = () => {
      setSettlement(!showSettlement)
    }

    return (
      <>
        <div className="table-responsive">
          <Table className="table-centered align-middle table-nowrap table-striped mb-0">
            <thead className="table-light">
              <tr>
                <th>Name</th>
                <th>Account</th>
                <th colSpan={2}>Chips</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>Super(super) Account</td>
                <td colSpan={2} className={`text-${type}`}>0</td>
              </tr>
              <tr>
                <td></td>
                <td>Own</td>
                <td colSpan={2} className={`text-${type}`}>1,524,525.97	</td>
              </tr>
              <tr>
                <td></td>
                <td>Cash</td>
                <td colSpan={2} className={`text-${type}`}>1</td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info cursor-pointer"><span className="badge bg-info-subtle text-info">A</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`} className="btn-sm" onClick={toggleHistory}>History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm" onClick={toggleSettlement}>Settlement</Button>
                </td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info"><span className="badge bg-info-subtle text-info">C</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`}className="btn-sm">History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm">Settlement</Button>
                </td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info cursor-pointer"><span className="badge bg-info-subtle text-info">A</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`} className="btn-sm">History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm">Settlement</Button>
                </td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info"><span className="badge bg-info-subtle text-info">C</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`}className="btn-sm">History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm">Settlement</Button>
                </td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info cursor-pointer"><span className="badge bg-info-subtle text-info">A</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`} className="btn-sm">History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm">Settlement</Button>
                </td>
              </tr>
              <tr>
                <td className="cursor-pointer" onClick={toggleList}>azizwinu</td>
                <td className="text-info"><span className="badge bg-info-subtle text-info">C</span> azizwinu A/C</td>
                <td className={`text-${type}`}>438</td>
                <td className="text-end">
                  <Button variant={`subtle-${type}`} className="btn-sm">History</Button>
                  <Button variant="subtle-dark" className="ms-2 btn-sm">Settlement</Button>
                </td>
              </tr>
            </tbody>
            <tfoot className="table-light">
              <tr>
                <th colSpan={2}>Total</th>
                <th colSpan={2} className={`text-${type}`}>1,722,178.58</th>
              </tr>
            </tfoot>
          </Table>
        </div>

        <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
          <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
              <div className='d-flex align-items-center'>Parent List of <span className='text-muted fw-normal mx-2'>(lcbook247-prime)</span></div>
              <Button variant="light btn-sm" onClick={toggleList}>
                  <i className="ri-close-line fs-xl align-middle"></i>
              </Button>
          </Modal.Header>
          <Modal.Body>
          Super Admin(lcbook247-prime)
          </Modal.Body>
        </Modal>

        <HistoryModal show={showHistory} clickHandler={toggleHistory} />

        <Modal show={showSettlement} onHide={toggleSettlement} className="zoomIn" scrollable>
          <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
              <div className='d-flex align-items-center'>Settlement</div>
              <Button variant="light btn-sm" onClick={toggleSettlement}>
                  <i className="ri-close-line fs-xl align-middle"></i>
              </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-floating mb-3">
              <Form.Control
                  type="number"
                  value={438}
              />
              <Form.Label>Chips </Form.Label>
            </div>
            <div className="form-floating">
              <Form.Control
                  type="text"
                  placeholder="Remark"
              />
              <Form.Label>Remark </Form.Label>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-top">
            <Button variant={`subtle-${type}`}>{type === 'success' ? 'Debit' : 'Credit'}</Button>
            <Button variant="subtle-dark" onClick={toggleSettlement}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
};

export default ChipSummaryTable;

