import { Button, Modal, Table } from 'react-bootstrap';

interface BookModalProps {
    show: any;
    clickHandler: any;
}

const HistoryModal = ({ show, clickHandler }: BookModalProps) => {
  
    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-2">
                <div className='d-flex align-items-center'>History</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-2 fs-md'>
                <div className="table-responsive">
                    <Table className="align-middle table-striped table-nowrap mb-0">
                        <thead className='table-light'>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Updated Amount</th>
                                <th scope="col">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default HistoryModal;