import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import TableContainer from "../TableContainer";
import { Button, Form, Modal, Pagination } from 'react-bootstrap';
import { BetsData } from 'pages/MatchDetail/DetailsRight';
import moment from 'moment';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface Props {
  betsDetails: BetsData | undefined,
  handlePreviousPage: () => void,
  handleNextPage: () => void,
  currentPage: number,
  getDeleteBet:any,
  setPayloadData: Dispatch<SetStateAction<{
    bet_id: string;
    user_id: string;
    is_void: boolean;
    is_fancy: number;
    password: string;
}>>,
paylodData: {
  bet_id: string;
  user_id: string;
  is_void: boolean;
  is_fancy: number;
  password: string;
}
active:boolean,
setActive: Dispatch<SetStateAction<boolean>>
}

const MatchDetailTable: FC<Props> = ({setActive,active, setPayloadData, paylodData, betsDetails, handlePreviousPage, handleNextPage, currentPage, getDeleteBet }) => {
  const [copied, setCopied] = useState(false);
 
  const [showPassword, setPassword] = useState(false);
 
  const [validated, setValidated] = useState(false);

  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);

  const itemsPerPage = 50;

  

  const totalDataCount = betsDetails?.data?.length || 0; 
  const totalPages = Math.ceil(totalDataCount / itemsPerPage); 

  const currentData = useMemo(() => {
    if (!betsDetails || !betsDetails.data) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return betsDetails.data.slice(startIndex, startIndex + itemsPerPage);
  }, [betsDetails, currentPage]);



  const handleOpenModals = (bet_id: string, user_id: string, is_fancy: number) => {
    setPayloadData((prev) => ({
      ...prev,
      bet_id,
      user_id,
      is_fancy
    }))
    setActive(true);
  }

  const handlePassValue = (e: any) => {
    setPayloadData((prev) => ({
      ...prev,
      password: e.target.value
    }))
    setValidated(false);
  }



const handleDeletedBet = (is_void: boolean) => {
  if (paylodData?.password.trim() === "") {
    setValidated(true);
    return;
  }
  setPayloadData((prev) => ({
    ...prev,
    is_void
  }))
  getDeleteBet();
}

  const columns = useMemo(() =>
    [
      {
        header: "S. No:",
        accessorKey: "sNo",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const serialNumber = (currentPage - 1) * itemsPerPage + cell.row.index + 1;
          return (
            <span>
              {serialNumber}  
              {cell?.row?.original?.delete_status === 0 && <i onClick={() => handleOpenModals(cell?.row?.original?.bet_id, cell?.row?.original?.user_id, cell.row.original.is_fancy)} className="cursor-pointer text-danger ri-delete-bin-line align-middle me-2"></i>}
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <div>{cell?.row?.original?.user_name}</div>
          );
        },
      },
      {
        header: "Domain",
        accessorKey: "domain_name",
        enableColumnFilter: true,
      },
      {
        header: "Market Selection",
        accessorKey: "MarketSelection",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <>
              <div>{cell?.row?.original?.market_name}</div>
              <div>{cell?.row?.original?.selection_name}</div>
            </>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Button variant='sm' className={cell?.row?.original === 1 ? 'btn-back' : 'btn-lay'}>
              {cell?.row?.original === 1 ? "Back" : "Lay"}
            </Button>
          );
        },
      },
      {
        header: "Rate Stake",
        accessorKey: "RateStake",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <>
              <div>{cell?.row?.original?.odds}/{cell?.row?.original?.size}</div>
              <div>{cell?.row?.original?.stack}</div>
            </>
          );
        },
      },
      {
        header: "Liability Placed Time",
        accessorKey: "Liability",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <>
              <span>
                <span className={`${cell?.row?.original?.liability < 0 ? "text-danger" : "text-success"} me-2`}>
                  {cell?.row?.original?.liability}
                </span>
                {cell.getValue()}
              </span>
              <div>{moment(cell?.row?.original?.createdAt).format('DD-MM-YY hh:mm A')}</div>
            </>
          );
        },
      },
      {
        header: "BetId IP Address",
        accessorKey: "BetId",
        enableColumnFilter: true,
        cell: (cell: any) => {
          return (
            <>
              <div>{cell?.row?.original?.bet_id}</div>
              <div>{cell?.row?.original?.ip_address}</div>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={currentData} // Use the current paginated data
        isPagination={false}
        isBordered={false}
        sorting={false}
        tableClass="table-centered align-middle mb-0 react-table table-striped table-nowrap matchDetailAllBet"
        theadClass="text-muted table-light"
        SearchPlaceholder='Search Products...'
        rowClass='backBorder'
        tdClass='text-dark'
      />

      <Pagination className="mt-3">
        <Pagination.Prev onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </Pagination.Prev>
        <Pagination.Item active>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={handleNextPage} disabled={currentPage >= totalPages || totalDataCount === 0}>
          Next
        </Pagination.Next>
      </Pagination>


      <Modal show={active} className="zoomIn" scrollable>
        <Modal.Body className='text-center '>
          <Form noValidate>
            <div className="form-floating">
              <Form.Control
                isInvalid={validated && paylodData?.password?.trim() === ""}  
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={paylodData?.password}
                onChange={handlePassValue}
                required

              />
              <Form.Label>Enter New Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPassword(!showPassword)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                Please enter your password.
              </Form.Control.Feedback>
            </div>
          </Form>
          <div className='mt-4'>
            <Button variant='light' className='mx-2' onClick={() => setActive(false)}>Cancel</Button>
            <Button className='mx-2' onClick={() => handleDeletedBet(false)}>Delete</Button>
            <Button className='mx-2' onClick={() => handleDeletedBet(true)}>Void</Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default MatchDetailTable;
