import { ApiResource } from "../apiService";

const userId =  localStorage.getItem("userId")

export const authResourcs: {
  [x: string]: ApiResource;
} = {
 
  LOGIN: {
    URL: "login/client-login",
    METHOD: "POST",
  },
  CHANGE_PASSWORD: {
    URL: `/user/updateForChangePasswordAfterLogin/${userId}`,
    METHOD: "POST",
  },
  
  SET_TRANS: {
    URL: "/user/setTransactionPassword",
    METHOD: "POST",
  },
  
  TELEGRAM_CODE: {
    URL: "/telegram/generateTelegramConnectionId",
    METHOD: "POST",
  },
  GET_EXPOSURE_SPORT: {
    URL: "/bet/getExposures",
    METHOD: "POST",
  },
  GET_EXPV1_SPORT: {
    URL: `/bet/getExposuresV1`,
    METHOD: "POST",
  },

  GET_CASINO_EXPOSURE: {
    URL: "/casino-exposures",
    METHOD: "POST",
  },
  SHOW_BALANCE: {
    URL: "/user/getUserBalance",
    METHOD: "POST",
  },
  GET_SPORT: {
    URL: "/sports/getSports",
    METHOD: "POST",
  },
  GET_SPORT_DATA: {
    URL: "/match/homeMatches",
    METHOD: "POST",
  },
  GET_NEWS: {
    URL: "/news/getNews",
    METHOD: "POST",
  },
  GET_SERIES: {
    URL: "/series/getSeries",
    METHOD: "POST",
  },
  ENABLED_FANCY: {
    URL: "/match/enableFancy",
    METHOD: "POST",
  },
  UPDATE_MATCH_STATUS: {
    URL: "/match/updateMatchStatus",
    METHOD: "POST",
  },
  UPDATE_EVENT: {
    URL: "/event/update",
    METHOD: "POST",
  },
  VALID_TOKEN: {
    URL: "/user/validateToken",
    METHOD: "GET",
  },
  GET_MATCHDETAILS: {
    URL: "/match/matchDetails",
    METHOD: "POST",
  },
  GET_MATCHDETAILS_RUNNER: {
    URL: "/match/matchDetailsRunners",
    METHOD: "POST",
  },
  FANCY_DATA: {
    URL: "/fancy/getFancies",
    METHOD: "POST",
  },
  FANCY_LIVE_DATA: {
    URL: "/fancy/getFancyLiveData",
    METHOD: "POST",
  },
  BETS: {
    URL: "/bet/bets",
    METHOD: "POST",
  },
  GETONLINE_FANCY: {
    URL: "/fancy/getOnlineApiFancy",
    METHOD: "POST",
  },
  UPDATE_FANCY_STATUS: {
    URL: "/fancy/updateFancyStatus",
    METHOD: "POST",
  },
  UPDATE_FANCY: {
    URL: "/fancy/updateFancy",
    METHOD: "POST",
  },
  GET_TEAM_POSITION: {
    URL: "/bet/getTeamPosition",
    METHOD: "POST",
  },
  GET_LIMIT: {
    URL: "/event/getLimites",
    METHOD: "POST",
  },
  UPDATE_LIMITS: {
    URL: "/event/updateLimites",
    METHOD: "POST",
  },

  ODDS_ABONDONED: {
    URL: "/bet/oddsAbandoned",
    METHOD: "POST",
  },
  UPDATE_MARKET_STATUS: {
    URL: "/market/updateMarketStatus",
    METHOD: "POST",
  },
  GET_MARKET_POSITION: {
    URL: "/market/getMarketAgentUserPositions",
    METHOD: "POST",
  },
  ADD_USER: {
    URL: "/user/detailsForAdd",
    METHOD: "POST",
  },
  USER_REGISTER: {
    URL: "/user/register",
    METHOD: "POST",
  },
  GET_CLOSE_USER: {
    URL: `/user/getClosedUsersList/${userId}`,
    METHOD: "POST",
  },
  GET_CLOSE: {
    URL: `/user/closeAccount`,
    METHOD: "POST",
  },
  USER_LIST_WITH_CHILD: {
    URL: `/user/userDetailsWithChildLevelDetails`,
    METHOD: "POST",
  },
  SHOW_PASSWORD: {
    URL: `/user/getRawPasswordOfUser`,
    METHOD: "POST",
  },
  UPDATE_CHILD_PASS: {
    URL: `/user/updateChildPassword`,
    METHOD: "POST",
  },
  CHIPS_OUT: {
    URL: `/account/chipInOut`,
    METHOD: "POST",
  },
  GET_COMMITTION: {
    URL: `/user/getCommission`,
    METHOD: "POST",
  },
  UPDATE_COMMITTION: {
    URL: `/userSettings/update-commission`,
    METHOD: "POST",
  },
  GET_LOGO: {
    URL: `/content/getLogo`,
    METHOD: "POST",
  },
  UPADATE_CR: {
    URL: `/user/updateCreditReference`,
    METHOD: "POST",
  },
  ALLOW_MULTI_LOGIN: {
    URL: `/user/allowAndNotAllowAgentsMultiLogin`,
    METHOD: "POST",
  },
  ALLOW_BETLOCK_LOCK: {
    URL: `/user/updateUserStatusBettingLockUnlock`,
    METHOD: "POST",
  },
  ALLOW_FANCYLOCK_LOCK: {
    URL: `/user/updateUserStatusFancyBetLock`,
    METHOD: "POST",
  },
  USER_ACC_LOCK: {
    URL: `/user/lockAccount`,
    METHOD: "POST",
  },
  CLOSE_ACCOUNT: {
    URL: `/user/closeAccount`,
    METHOD: "POST",
  },
  EVENT_LOCK: {
    URL: `/user/update/eventSettingsCheck`,
    METHOD: "POST",
  },
  GET_PAETNERSHIP_LIST: {
    URL: `/user/getPartnershipListByUserId`,
    METHOD: "POST",
  },
  UPDATE_PARTNERSHIP: {
    URL: `/user/updatePartnershipList`,
    METHOD: "POST",
  },
  HORSE_RACING: {
    URL: `/match/getCountryCodeList`,
    METHOD: "POST",
  },
  GET_EVENT_LIMIT: {
    URL: `/event/getEventsLimit`,
    METHOD: "POST",
  },
  DELETE_BET: {
    URL: `/bet/deleteBet`,
    METHOD: "POST",
  },
  USER_SETTING: {
    URL: `/userSettings/getSportsWise`,
    METHOD: "POST",
  },
  USER_SETTING_UPDATE: {
    URL: `/userSettings/update`,
    METHOD: "POST",
  },
  GET_ALL_WEBSITE: {
    URL: `/website/getAllWebsite`,
    METHOD: "GET",
  },
  UPDATE_STACK: {
    URL: `/user/updateMatchStack`,
    METHOD: "POST",
  },
  SHOW_AGENT: {
    URL: `/user/showAgents`,
    METHOD: "POST",
  },
  GET_ACTIVE_LOG: {
    URL: `/user/getActivityLogs`,
    METHOD: "POST",
  },
  GET_ALL_LOGOUT: {
    URL: `/user/logoutAll`,
    METHOD: "POST",
  },
  GET_SPORT_LIST: {
    URL: `/sport/getSports`,
    METHOD: "POST",
  },
  GET_MATCHES: {
    URL: `/match/getMatches`,
    METHOD: "POST",
  },
  UPDATE_SPORT_STATUS: {
    URL: `/sports/updateSportsStatus`,
    METHOD: "POST",
  },
  GET_MARKET: {
    URL: `/market/getMarkets`,
    METHOD: "POST",
  }, 
};
